const STORAGE = {
  LOCALE: 'locale',
};

export const getLocale = userName => {
  return localStorage.getItem(`${STORAGE.LOCALE}-${userName}`) || 'en';
};

export const setLocale = (userName, locale) => {
  localStorage.setItem(`${STORAGE.LOCALE}-${userName}`, locale);
};
